import {
  ActionButtonSkeleton,
  HStack,
  Icon,
  MoreBar,
  StatusPopup,
  TextButton,
  Text,
  useStatusPopup,
  Spinner,
} from '@revolut/ui-kit'
import { captureException } from '@sentry/react'
import { navigateTo } from '@src/actions/RouterActions'
import { createDraftGoal } from '@src/api/goals'
import { useGetSelectors } from '@src/api/selectors'
import { EntityTypes, selectorKeys } from '@src/constants/api'
import { ROUTES } from '@src/constants/routes'
import { useOrgEntity } from '@src/features/OrgEntityProvider/OrgEntityProvider'
import { GoalContentType } from '@src/interfaces/goals'
import { isOnboardingPath } from '@src/pages/OnboardingChecklistV2/common/helpers'
import { selectPermissions, selectUser } from '@src/store/auth/selectors'
import { PermissionTypes } from '@src/store/auth/types'
import { pathToUrl } from '@src/utils/router'
import React, { ReactNode, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

const useDefaultWriteAccess = () => {
  const { entity } = useOrgEntity()

  const permissions = useSelector(selectPermissions)
  const canAddGoals = !!entity?.data.field_options?.permissions?.includes(
    PermissionTypes.CanAddGoals,
  )

  if (!entity) {
    return false
  }

  switch (entity.type) {
    case EntityTypes.company:
    case EntityTypes.companyV2:
      return [
        PermissionTypes.CanAddCompanyGoal,
        PermissionTypes.CanAddDepartmentGoal,
        PermissionTypes.CanAddTeamGoal,
      ].some(permission => permissions.includes(permission))
    default:
      return canAddGoals
  }
}

export const AddGoalAction = ({
  variant = 'default',
  label,
}: {
  variant?: 'text' | 'default'
  label?: ReactNode
}) => {
  const location = useLocation()
  const { navigateWithEntity, entity } = useOrgEntity()
  const { data: contentTypes, isLoading } = useGetSelectors<GoalContentType>(
    selectorKeys.goal_content_types,
  )
  const canAdd = useDefaultWriteAccess()
  const [pending, setPending] = useState(false)
  const user = useSelector(selectUser)
  const statusPopup = useStatusPopup()

  if (isLoading) {
    return <ActionButtonSkeleton />
  }

  const handleNew = async () => {
    setPending(true)

    const isOnboarding = isOnboardingPath()

    try {
      const response = await createDraftGoal({
        owner: { id: user.id },
        is_company:
          entity?.type === EntityTypes.company || entity?.type === EntityTypes.companyV2,
        content_type:
          entity?.type === EntityTypes.company || entity?.type === EntityTypes.companyV2
            ? undefined
            : contentTypes?.find(({ model }) => model === entity?.type),
        object_id: entity?.data.id,
      })
      navigateWithEntity(
        pathToUrl(
          isOnboarding
            ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.EDIT
            : ROUTES.FORMS.GOAL.EDIT,
          { id: response.data.id },
        ),
        {
          reviewCycleId: new URLSearchParams(location.search).get('cycle__id'),
          isNew: true,
        },
      )
    } catch (err) {
      captureException(err)
      const description =
        err.response?.data?.detail || 'Something went wrong. Please try again.' // ToDo: agree on possible error from BE

      statusPopup.show(
        <StatusPopup variant="error">
          <StatusPopup.Title>Failed to create goal</StatusPopup.Title>
          <StatusPopup.Description>{description}</StatusPopup.Description>
        </StatusPopup>,
      )
      setPending(false)
    }
  }

  const navigate = () => {
    const isOnboarding = isOnboardingPath()
    navigateTo(
      pathToUrl(
        isOnboarding
          ? ROUTES.ONBOARDING_CHECKLIST_V2.GOALS.GOAL.CREATE.SELECT_LEVEL
          : ROUTES.FORMS.GOAL.CREATE.SELECT_LEVEL,
      ),
      {
        reviewCycleId: new URLSearchParams(location.search).get('cycle__id'),
        isNew: true,
      },
    )
  }

  const renderButton = () => {
    const onClick =
      !entity ||
      (entity.type && [EntityTypes.company, EntityTypes.companyV2].includes(entity.type))
        ? navigate
        : handleNew
    if (variant === 'text') {
      return (
        <TextButton disabled={pending} onClick={onClick}>
          <HStack align="center" space="s-8">
            {pending ? <Spinner size={16} /> : <Icon name="Plus" size={16} />}
            <Text>{label || 'Add new goal'}</Text>
          </HStack>
        </TextButton>
      )
    }
    return (
      <MoreBar.Action pending={pending} useIcon="Plus" onClick={onClick}>
        {label || 'Add new goal'}
      </MoreBar.Action>
    )
  }

  return canAdd ? renderButton() : null
}
