import React, { ReactNode } from 'react'
import { Image, Text, Token, Flex } from '@revolut/ui-kit'

interface Props {
  title: ReactNode
  description?: ReactNode
  action?: ReactNode
}

export const EmptyTableRaw = ({ title, description, action }: Props) => {
  return (
    <Flex gap="s-16" alignItems="center" flexDirection="column" py="s-40">
      <Image
        size={104}
        image={{
          default: 'https://assets.revolut.com/assets/3d-images-v2/3D299.png',
          '2x': 'https://assets.revolut.com/assets/3d-images-v2/3D299@2x.png',
          '3x': 'https://assets.revolut.com/assets/3d-images-v2/3D299@3x.png',
        }}
      />
      <Text variant="emphasis1">{title}</Text>
      <Text variant="body2" color={Token.color.greyTone50}>
        {description}
      </Text>
      {action}
    </Flex>
  )
}
