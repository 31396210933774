import React from 'react'
import { RoadMapTablesProps, useRenderRoadmapTables } from './table'
import { Box, Cell, Flex, Text } from '@revolut/ui-kit'
import Stat from '@src/components/Stat/Stat'
import { getPercentColor } from '@src/components/ColumnInserts/ColoredPercent/ColoredPercent'
import { formatPercentage } from '@src/utils/format'
import {
  CycleFilter,
  CycleFilterType,
} from '@src/components/Inputs/Filters/FilterSelect/CycleFilter/CycleFilter'
import { FilterSelectType } from '@src/components/Inputs/Filters/FilterSelect/FilterSelect'
import { AddRoadmapMoreBarAction } from '@src/pages/Forms/RoadmapForm/common/AddRoadmapMoreBarAction'
import { EntityTypes } from '@src/constants/api'
import { RoadmapActionsBar, useRoadmapRefresh } from '.'
import RoadmapLockedChangesBanner from '../Banners/RoadmapLockedChangesBanner'
import { RoadmapSettingsInterface } from '@src/interfaces/settings'
import MoreInfoButton from '@src/components/MoreInfoButton/MoreInfoButton'
import { FETCH_JIRA_ROADMAP } from '@src/constants/externalLinks'
import { useGetRoadmapSettings } from '@src/api/settings'
import { useIsNewLayout } from '@src/pages/EmployeeProfile/Layout/helpers'
import { TableNames } from '@src/constants/table'
import { PerformanceLayoutCycleFilter } from '@components/Inputs/Filters/FilterSelect/CycleFilter/PerformanceLayoutCycleFilter'
import { EmptyTableRaw } from '@src/components/Table/EmptyTableRaw'
import { FormattedMessage } from 'react-intl'

export interface RoadmapTabProps extends RoadMapTablesProps {
  allowRefresh: boolean
  addRoadmapProps: {
    entityType: EntityTypes
    id: number
    backUrl: string
    name: string
    tags?: string[]
  }
  triggerRefresh: (cycleId?: string | number) => Promise<void>
  tableInfoMessage?: React.ReactNode
  roadmapSettings?: RoadmapSettingsInterface
  showTableInfo: boolean
  newLayoutNavigation?: React.ReactElement
}

export const RoadmapTab = (props: RoadmapTabProps) => {
  const {
    addRoadmapProps,
    triggerRefresh,
    tableInfoMessage,
    roadmapSettings,
    allowRefresh,
    showTableInfo,
    newLayoutNavigation,
    ...tableOuterProps
  } = props
  const { allowEdit, reviewCycle } = tableOuterProps
  const { table, changelogSwitcher, changelogState, renderTable } =
    useRenderRoadmapTables(tableOuterProps)
  const isNewLayout = useIsNewLayout()

  const { renderRefreshNote, refreshButton, pendingTaskId } = useRoadmapRefresh({
    epics: table.data.map(r => r.key),
    onAfterRefresh: () => table.refresh(),
  })
  const { data: settings } = useGetRoadmapSettings()
  const moreBarPending = !roadmapSettings || !props.reviewCycle

  return (
    <Cell flexDirection="column" minHeight={0}>
      <Flex flexDirection="column" width="100%">
        <Flex mb="s-24" justifyContent="space-between">
          <Flex>
            {isNewLayout && addRoadmapProps.entityType === EntityTypes.employee ? (
              <PerformanceLayoutCycleFilter
                onFilterChange={table.onFilterChange}
                columnName="review_cycle__offset"
                filter={table.filterBy}
              />
            ) : (
              <CycleFilter
                type={CycleFilterType.NewUI}
                onFilterChange={table.onFilterChange}
                columnName="review_cycle__offset"
                filter={table.filterBy}
                filterInputType={FilterSelectType.SingleSelect}
              />
            )}
            <Stat
              label="Progress"
              val={
                table?.stats?.avg_done !== undefined ? (
                  <Text color={getPercentColor((table?.stats?.avg_done || 0) * 100)}>
                    {formatPercentage(table?.stats?.avg_done)}
                  </Text>
                ) : (
                  'N/A'
                )
              }
              ml="s-32"
            />
          </Flex>
          {!!newLayoutNavigation &&
            ((isNewLayout && props.tableProps.name === TableNames.EmployeeRoadmap) ||
              props.tableProps.name === TableNames.DepartmentRoadmap) &&
            newLayoutNavigation}
        </Flex>
        <Flex mb="s-16">
          <RoadmapActionsBar pending={moreBarPending}>
            {allowEdit && (
              <AddRoadmapMoreBarAction
                {...addRoadmapProps}
                onAfterSubmit={() => table.refresh()}
                reviewCycle={reviewCycle || null}
              />
            )}
            {changelogSwitcher}
            {allowRefresh && refreshButton}
            {settings?.jira_epics_enabled ? (
              <MoreInfoButton href={FETCH_JIRA_ROADMAP} />
            ) : null}
          </RoadmapActionsBar>
        </Flex>
        <RoadmapLockedChangesBanner
          hidden={!changelogState.active}
          pb="s-8"
          reviewCycle={reviewCycle || null}
        />
        {!table.data.length && !table.loading ? (
          <EmptyTableRaw
            title={
              <FormattedMessage
                defaultMessage="Your roadmaps goes here"
                id="performance.roadmaps.emptyTable.title"
              />
            }
            description={
              <FormattedMessage
                defaultMessage="All your epics will appear here"
                id="performance.roadmaps.emptyTable.description"
              />
            }
            action={
              allowEdit ? (
                <AddRoadmapMoreBarAction
                  {...addRoadmapProps}
                  variant="text"
                  onAfterSubmit={() => table.refresh()}
                  reviewCycle={reviewCycle || null}
                />
              ) : null
            }
          />
        ) : (
          <Flex style={{ position: 'relative' }} flex="1 0">
            {renderTable({
              loading: !!pendingTaskId,
            })}
          </Flex>
        )}

        {allowRefresh && (
          <Box mt="s-12">
            {renderRefreshNote({ lastUpdated: table?.stats?.refresh_date_time })}
          </Box>
        )}
      </Flex>
    </Cell>
  )
}
